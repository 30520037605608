export default class ErrorException extends Error {
    code: string | number;
    type: string;

    constructor(
        message: string,
        code: string | number = 500,
        type: string = '') {
        super(`${message}`);
        this.message = `${message}`;
        this.code = code;
        this.type = type;
    }
}
